/* CameraScreen */
.main-container {
  display: flex;
  flex-direction: column;
  height: 100dvh;
  /* dvhにすることでURLバーにボタン見えない問題を回避可能 */
  width: 100vw;
  /* Ensure full viewport width */
  margin: 0;
  /* Remove any default margins */
  overflow: hidden;
  /* Hide overflow */
  transition: flex-direction 0.3s ease;
  /* Smooth transition for orientation change */
}

.stream-container {
  position: relative;
  overflow: hidden;
}

.camera-footer {
  flex-grow: 1;
  display: flex;
  justify-content: space-between;
  /* Space between items */
  align-items: center;
  /* Align items vertically in the center */
  padding: 0 30px;
  /* Add padding to ensure buttons are not stuck to the edges */
  background-color: black;
  /* Set the background color to black */
}

.popup-window {
  position: fixed;
  padding: 20px;
  background-color: rgba(255, 255, 255, 0.6);
  border-radius: 10px;
  z-index: 1000;
}

.popup-button {
  position: absolute;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0);
  border: none;
  color: black;
}

.explanation {
  font-size: 13px;
  text-align: center;
}

.explanation a {
  color: blue;
  text-decoration: underline;
  cursor: pointer;
}

.explanation a:hover {
  color: darkblue;
}

.popup-close-button {
  position: absolute;
  background: transparent;
  border: none;
  font-size: 25px;
  cursor: pointer;
}

.video-element {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.character-image {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.camera-back-button,
.camera-switch-button {
  z-index: 10;
  padding: 10px;
  background-color: transparent;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

.capture-button {
  /* ボタンの幅 */
  width: 70px;
  /* ボタンの高さ */
  height: 70px;
  /* 円形にする */
  border-radius: 50%;
  /* ボタンの背景色 */
  background-color: white;
  /* ボーダーをなくす */
  border: none;
  /* マウスポインターを指にする */
  cursor: pointer;
  /* フレックスボックスを使用 */
  display: flex;
  /* コンテンツを中央に配置 */
  justify-content: center;
  /* コンテンツを中央に配置 */
  align-items: center;
  /* テキストのサイズ */
  font-size: 16px;
  position: relative;
  transition: background-color 0.3s;
}

.camera-inner-circle {
  width: 60px;
  /* 内部の白い丸の幅 */
  height: 60px;
  /* 内部の白い丸の高さ */
  border-radius: 50%;
  border: 2px solid black;
  /* 円形にする */
  background-color: white;
  /* 白い背景色 */
  display: flex;
  /* フレックスボックスを使用 */
  justify-content: center;
  /* コンテンツを中央に配置 */
  align-items: center;
  /* コンテンツを中央に配置 */
  position: absolute;
  /* 外側のボタン内で絶対位置に配置 */
  top: 50%;
  /* 親要素の中央に配置 */
  left: 50%;
  /* 親要素の中央に配置 */
  transform: translate(-50%, -50%);
}

@media (orientation: landscape) {
  .main-container {
    flex-direction: row;
  }

  .camera-footer {
    flex-direction: column-reverse;
    padding: 30px 0;
  }

  .popup-window {
    width: 50%;
    max-height: 40%;
    top: 5%;
    right: 30%;
  }

  .popup-button {
    top: 5%;
    right: 25%;
  }

  .popup-close-button {
    top: 3%;
    right: 1%;
  }
}

@media (orientation: portrait) {
  .main-container {
    flex-direction: column;
  }

  .camera-footer {
    flex-direction: row;
    padding: 0 20px;
  }

  .popup-window {
    width: 80%;
    max-height: 17%;
    top: 60%;
    right: 4%;
  }

  .popup-button {
    top: 91%;
    right: 0%;
  }

  .popup-close-button {
    top: 3%;
    right: 1%;
  }
}
