.picture-check-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
}

.pictures-container {
  display: flex;
  flex-wrap: wrap; /* Allow wrapping of items */
  justify-content: center;
  margin-bottom: 20px;
}

.picture-wrapper {
  position: relative;
  margin: 10px auto; /* Center the images and add vertical space */
  border: 1px solid #cccccc;
  border-radius: 8px;
  cursor: pointer;
  flex: 0 0 calc(100% - 20px); /* Set width to 100% minus the desired margin */
  box-sizing: border-box; /* Ensure padding and border are included in the element's total width and height */
}

.picture-wrapper.selected {
  border: 2px solid #00bcd4;
}

.watermarked-picture {
  width: 100%;
  height: auto;
  border-radius: 8px;
}

.try-number,
.instruction {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 20px;
}

.buttons-container {
  display: flex;
  justify-content: space-around; /* 子要素間に均等なスペースを持たせる */
  width: auto; /* 子要素に合わせる */
  max-width: 400px;
  gap: 10px; /* 子要素間のスペースを指定 */
}

.buttons-container .retake-button {
  background-color: #cccccc;
  color: black;
}

.buttons-container .confirm-button {
  background-color: #6200ea;
  color: white;
}

.buttons-container .retake-button,
.buttons-container .confirm-button {
  padding: 14px 20px;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s;
  width: 160px;
  margin-top: 24px;
  box-sizing: border-box;
}

.buttons-container .confirm-button:hover {
  background-color: #3700b3;
}

.buttons-container .retake-button:hover {
  background-color: #b3b3b3; /* Slightly darker gray for hover effect */
}
