@media (orientation: portrait) {
    .get-picture-container {
        width: 100vw;
        height: 100vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        overflow: hidden;

    }

    .picture-container {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: center;
        position: relative;
    }

    .picture {
        width: 100vw;
        height: auto;
        object-fit: contain;
    }

    .download-button-container {
        flex-grow: 1; 
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        padding: 0 30px;
        background-color: black;
    }
}

@media (orientation: landscape) {
    .get-picture-container {
        width: 100vw;
        height: 100vh;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        overflow: hidden;
    }

    .picture-container {
        display: flex;
        height: 100%;
        align-items: center;
        justify-content: center;
        position: relative;
    }

    .picture {
        height: 100vh;
        width: auto;
        object-fit: contain;
        object-position: left center; 
    }
    .download-button-container {
        flex-grow: 1; 
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        flex-direction: column-reverse;
        padding: 0 30px;
        background-color: black;
    }
}
  
  .download-picture-button {
    padding: 10px 20px;
    cursor: pointer;
    margin: 10px;
    font-size: 1rem;
    border: none;
    border-radius: 5px;
    transition: background-color 0.3s ease;
    background-color: #4CAF50;
    color: white;
  } 