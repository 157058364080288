.swiper {
  width: 100%;
  height: 100%;
  background-color: transparent;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background-color: transparent;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.swiper-pagination-bullet {
  background-color: white; /* ページネーションの丸の色を設定 */
}

.swiper-button-prev::after,
.swiper-button-next::after {
  font-size: 25px; /* 矢印アイコンのサイズ */
  color: #eeeeee;
}