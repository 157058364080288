.email-form-container {
  background-color: #f4f4f4;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  margin: 0;
}

.email-form-card {
  background-color: #fff;
  padding: 40px 40px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 400px;
  width: 100%;
}

.email-form-notice {
  margin-bottom: 20px;
  color: #555555;
  text-align: center;
  font-size: 12px;
}

.email-form-title {
  margin-bottom: 20px;
  color: #333;
  text-align: center;
  font-size: 20px;
  font-weight: bold;
}

.email-form-group {
  margin-top: 12px;
  margin-bottom: 5px;
}

.email-form-label {
  display: block;
  margin-bottom: 5px;
  color: #555;
  font-weight: bold;
  font-size: 12px;
}

.email-form-control {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  box-sizing: border-box;
  transition: border-color 0.3s;
}

.email-form-control:focus {
  border-color: #6200ea;
  outline: none;
}

.email-form-error-message {
  color: #ff5c5c;
  font-size: 14px;
}

.email-form-submit-button {
  background-color: #6200ea;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s;
  width: 100%;
  margin-top: 24px;
  box-sizing: border-box;
}

.email-form-submit-button:hover {
  background-color: #3700b3;
}
