.picture-share-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
}

.selected-picture {
  width: 80%;
  max-width: 500px;
  border-radius: 8px;
  margin-bottom: 20px;
  border: 1px solid #cccccc;
}

.confirmation-message {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 20px;
  text-align: center;
}

.picture-share-container .buttons-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 300px;
}
.picture-share-container .buttons-container .share-button {
  background-color: #6200ea;
  color: white;
}
.picture-share-container .buttons-container .return-button {
  background-color: #cccccc;
  color: black;
}
.picture-share-container .buttons-container .share-button,
.picture-share-container .buttons-container .return-button {
  padding: 14px 20px;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s;
  width: 160px;
  margin-top: 24px;
  box-sizing: border-box;
}

.save-button:hover,
.share-button:hover,
.return-button:hover {
  background-color: #b3b3b3; /* Slightly darker gray for hover effect */
}
