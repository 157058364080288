.consume-psyche-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.consume-psyche-page img {
  max-width: 100%; /* Ensures the image does not exceed the container's width */
  height: auto;
  margin-bottom: 20px; /* Adds space below the image */
}

.consume-psyche-page .back-button {
  border: none;
  align-self: flex-start; /* Aligns the back button to the start of the container */
  margin-bottom: 20px; /* Adds space below the back button */
  color: #3700b3;
  font-weight: bold;
  background-color: transparent;
}

.consume-psyche-page .character-name {
  font-size: 1.5em;
  font-weight: bold;
}

.consume-psyche-page .sekai-info {
  font-size: 12px;
  color: #666666;
  margin-bottom: 28px; /* Adds space below the character name */
}

.consume-psyche-page .message {
  margin-bottom: 20px; /* Adds space below the message */
}

.consume-psyche-page .notice-message {
  text-align: center;
  margin-bottom: 20px; /* Adds space below the message */
}

.consume-psyche-page .start-photo-button {
  padding: 10px 20px;
  font-size: 1em;
  font-weight: bold;
  width: 300px;
  background-color: #cccccc;
  color: black;
  border: none;
  border-radius: 4px;
  padding: 15px 20px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
  display: inline-block;
  text-align: center;
  margin-bottom: 20px;
}

.consume-psyche-page .start-photo-button:hover {
  background-color: #b3b3b3; /* Slightly darker gray for hover effect */
  transform: scale(1.05);
}

/*.consume-psyche-page .start-photo-button:active {
  background-color: #6200ea;
  transform: scale(0.95);
}

.consume-psyche-page .start-photo-button:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(98, 0, 234, 0.5);
}*/

.callout {
  border: 1px solid #ccc;
  border-left-width: 5px; /* 左側のボーダーを太くする */
  border-radius: 4px;
  background-color: #f9f9f9;
  padding: 20px;
  margin: 20px 0;
  font-size: 16px;
  position: relative;
  width: 350px;
}

.callout::before {
  content: "💡"; /* アイコンを追加 */
  font-size: 24px;
  position: absolute;
  top: 10px;
  left: 10px;
}

.callout h3 {
  margin-top: 0;
  margin-bottom: 10px;
  margin-left: 20px;
  font-size: 18px;
  font-weight: bold;
}

.callout p {
  margin: 0;
  margin-left: 20px;
  font-size: 14px;
}

.sweet-loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 90vh;
}

.consume-psyche-page .start-limited-photo-button {
  padding: 10px 20px;
  font-size: 1em;
  font-weight: bold;
  width: 300px;
  background-color: #6200ea;
  border: none;
  border-radius: 4px;
  color: white;
  padding: 15px 20px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
  display: inline-block;
  text-align: center;
  margin-bottom: 20px;
}

.consume-psyche-page .start-limited-photo-button:hover {
  background-color: #3700b3;
  transform: scale(1.05);
}

.consume-psyche-page .start-limited-photo-button:active {
  background-color: #6200ea;
  transform: scale(0.95);
}

.consume-psyche-page .start-limited-photo-button:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(98, 0, 234, 0.5);
}

.limited-time {
  font-size: 12px;
  color: red;
  margin-top: 20px;
  font-weight: bold;
  margin: 0;
  padding: 0;
}