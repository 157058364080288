.tabs {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: space-around;
  padding: 10px;
  background-color: #fefefe;
  box-shadow: 0 -4px 6px -1px rgba(0, 0, 0, 0.1),
    0 -2px 4px -1px rgba(0, 0, 0, 0.06); /* 上方向のボックスシャドウ */
}

.tab {
  flex: 1;
  padding: 10px 0;
  text-align: center;
  font-size: 1.2em;
  background: none;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s;
  color: #1d2088;
}

.tab.active {
  border-radius: 8px;
  background-color: #ddd;
  font-weight: bold;
}
