.waiting-flex-container {
    flex-grow: 1;
    display: flex;
    justify-content: center;
    /* Space between items */
    align-items: center;
    /* Align items vertically in the center */
    padding: 0 30px;
    /* Add padding to ensure buttons are not stuck to the edges */
    background-color: black;
    /* Set the background color to black */
}

@media (orientation: portrait) {
    .waiting-screen {
        width: 100vw;
        height: 100dvh;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        margin: 0;
    }

    .image-container {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: center;
        position: relative;
    }

    .combined-image {
        width: 100vw;
        height: auto;
        object-fit: contain;
    }

    .loader {
        position: absolute;
        /* top: 50%;
        left: 50%; */
        /* transform: translate(-50%, -50%);
        background-color: aqua; */
        /* Add your loader styles here */
    }

    .waiting-instruction-message {
        padding: 10px 20px;
        margin: 10px;
        font-size: 1rem;
        background-color: black;
        color: white;
    }

    .waiting-flex-container {
        flex-direction: row;
        width: 100vw;
        height: auto;
        /* padding: 0 60px; */
    }
}

@media (orientation: landscape) {
    .waiting-screen {
        width: 100vw;
        height: 100dvh;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        margin: 0;
    }

    .image-container {
        display: flex;
        height: 100%;
        align-items: center;
        justify-content: center;
        position: relative;
    }

    .combined-image {
        height: 100vh;
        width: auto;
        object-fit: contain;
        object-position: left center;
    }

    .loader {
        position: absolute;
        /* top: 50%;
        left: 50%; */
        /* transform: translate(-50%, -50%);
        background-color: aqua; */
        /* Add your loader styles here */
    }

    .waiting-instruction-message {
        padding: 10px 20px;
        margin: 10px;
        font-size: 1rem;
        background-color: black;
        color: white;
    }

    .waiting-flex-container {
        width: auto;
        height: 100dvh;
        flex-direction: column-reverse;
        /* padding: 30px 0; */
    }
}
