.mygallery {
  padding: 20px;
  text-align: center;
}

.mygallery .back-button {
  background: none;
  border: none;
  color: #3700b3;
  font-weight: bold;
  font-size: 1em;
  cursor: pointer;
  margin-bottom: 20px;
}

.gallery-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
}

.mygallery h2 {
  font-weight: bold;
  margin-bottom: 20px;
}

.gallery-item {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.gallery-image {
  width: 100%;
  height: 100%;
  display: block;
  object-fit: cover;
}
