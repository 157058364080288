.mypage {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  height: 100%;
}

.mypage .profile-section {
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 500px;
  margin-bottom: 30px;
}

.mypage .profile-image {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin-right: 20px;
}

.mypage .profile-details {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.mypage .user-name {
  font-size: 1.5em;
  font-weight: bold;
  margin-bottom: 10px;
}

.mypage .psyche-count p {
  margin: 0; /* デフォルトのマージンをリセット */
}

.mypage .psyche-count .psyche-unit {
  font-size: 14px; /* デフォルトのマージンをリセット */
}

.mypage .psyche-count {
  font-size: 1.2em;
  font-weight: bold;
  display: flex;
  align-items: center; /* 縦方向に中央揃え */
  gap: 10px;
}

.mypage .psyche-count .psyche-icon {
  width: 24px; /* アイコンのサイズ（必要に応じて調整） */
  height: 24px;
}

.mypage .actions {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 500px;
  color: #333;
  border-radius: 10px;
  padding: 2px 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: white;
}

.mypage .action-item {
  width: 100%;
  padding: 15px;
  text-align: center;
  font-size: 1.2em;
  border-bottom: 1px solid #ccc;
  cursor: pointer;
  transition: background-color 0.3s;
  font-weight: bold;
}

.mypage .action-item:last-child {
  border-bottom: none;
}

.mypage .action-item:hover {
  background-color: #e0e0e0;
}
