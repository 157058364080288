.landing-container {
  display: flex;
  justify-content: center; /* 横方向に中央揃え */
  align-items: center; /* 縦方向に中央揃え（必要に応じて） */
  flex-direction: column;
  background-color: #f5f5f5;
  padding-top: 40px;
}

.content {
  flex: 1;
  padding-top: 60px; /* ヘッダーの高さと一致させる */
  padding-bottom: 80px; /* フッターの高さと一致させる */
  overflow-y: auto; /* main要素のみスクロール可能に */
  -webkit-overflow-scrolling: touch;
  background-color: #f5f5f5;
  min-height: 100vh;
}

.soul-array-main-container {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  justify-content: center;
  align-items: flex-start;
  padding: 16px;
}

.card-link {
  text-decoration: none;
  color: inherit;
  width: 200px;
}

.card {
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  width: 100%;
  text-align: center;
  background-color: #fff;
  margin: 16px 0;
}

.card-image {
  width: 100%;
  height: auto;
  display: block;
}

.card-name {
  padding: 16px;
  font-size: 1.2em;
  font-weight: bold;
  color: #333;
}

.tutorial-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* 背景を不透明な灰色に */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000; /* 他の要素の上に表示 */
}

.popup-content {
  background-color: #444; /* 背景色を濃い灰色に */
  padding: 20px;
  border-radius: 6px;
  text-align: center;
  width: 90%;
  color: #fff; /* 文字色を白に */
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
}

.popup-content h2 {
  font-weight: bold;
}

.popup-instruction {
  /* text-align: left; */
  margin-top: 10px;
}

.popup-instruction .title {
  font-weight: bold;
  font-size: 20px;
  margin-bottom: 2px;
}

.popup-instruction .point {
  font-size: 16px;
  margin: 0;
}

.popup-buttons button {
  background-color: #888; /* デフォルトのボタン背景色を灰色に */
  border: none;
  padding: 10px 20px;
  margin: 10px;
  border-radius: 4px;
  color: #fff; /* ボタンの文字色を白に */
  cursor: pointer;
}

.popup-buttons button:first-child {
  background-color: #84f28c; /* 「今撮ってみる」ボタンの背景色をグリーンに */
  color: #1b1c1b; /* 文字色を白に */
  font-weight: bold; /* 文字を太字に */
}

/* Media query for mobile devices */
@media (max-width: 768px) {
  .soul-array-main-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 0 32px;
    padding: 40px;
    padding-top: 0;
  }

  .card-link {
    /* width: calc(100% - 32px);
    margin: 0 auto; */
    position: relative;
    width: 100%; /* カードの横幅をグリッドの列幅に合わせる */
    overflow: hidden;
  }

  .card {
    display: block;
    background: white;
    border: 1px solid #ccc;
    border-radius: 10px;
    overflow: hidden; /* はみ出た内容を隠す */
    transition: box-shadow 0.3s;
  }

  .card-image {
    width: 100%; /* 画像の幅をカードに合わせる */
    height: 160px; /* 画像の高さを固定 */
    object-fit: cover; /* 画像をカバーするように調整 */
    object-position: center; /* 画像を中央に配置 */
  }
}

.limited-time-text {
  margin-top: 10px;
  font-weight: bold;
  font-family: sans-serif;
  font-size: 18px;
  background-color: #5c60d7;
  color: #f0f0f0; /* 背景色 */
  padding: 10px 20px; /* 内側の余白 */
  border: 0px solid #1d2088; /* 枠線 */
  display: inline-block; /* 要素をインラインブロックにする */
  text-align: center; /* テキストを中央揃えにする */
  border-radius: 4px; /* 角丸 */
}

.time-limit {
  font-size: 16px;
  color: #f4f4f4;
  margin-top: 20px;
  font-weight: bold;
  margin: 0;
  padding: 0;
}

.limited-time-explanation {
  font-size: 12px;
  margin-top: 10px;
  margin-bottom: 0;
  padding-bottom: 0;
}

.page-main-container {
  display: flex;
  justify-content: center; /* 横方向に中央揃え */
  align-items: center; /* 縦方向に中央揃え（必要に応じて） */
  flex-direction: column;
  background-color: #f5f5f5;
}

.maintenance-notice {
  position: fixed;
  top: 60px;
  left: 0;
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 12px 20px;
  z-index: 1000;
  font-weight: bold;
  color: black;
  background-color: #F2E2C3;
  margin: 0;
  padding: 0;
}

.maintenance-notice .close-button {
  position: absolute;
  right: 20px;
  font-size: 1.5em;
  cursor: pointer;
  border: none;
  background-color: transparent;
}
