.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 20px;
  background-color: #f9f9f9;
  z-index: 1000;
  background-color: rgba(255, 255, 255, 0.6); /* 半透明の白色 */
  backdrop-filter: blur(10px);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.header .profile-image {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.header .psyche-count {
  font-size: 1.5em;
  font-weight: bold;
  display: flex;
  align-items: center;
}

.header .psyche-count .psyche-icon {
  height: 1.5em;
}
