/* Container holding the form elements */
.purchase-screen-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    padding: 20px;
    max-width: 500px; /* Adjust based on your preference */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Soft shadow for a bit of depth */
    border-radius: 8px; /* Slightly rounded corners for a modern look */
    background-color: #ffffff; /* White background */
}

/* Container for the watermarked image */
.pincture-container-on-purchas-page {
    margin-bottom: 20px; /* Space between image and payment form */
    text-align: center; /* Centers the no-image text */
}

/* Styling for the watermarked image itself */
.watermarked-picture-on-purchase-page {
    max-width: 80%; /* Ensures image is responsive and fits container */
    height: auto; /* Keeps the image aspect ratio */
    border-radius: 4px; /* Slightly rounds the image corners */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Soft shadow for the image */
}

/* Styles for the purchase button */
.purchase-button {
    margin-top: 20px; /* Adds space above the button */
    font-size: 14px; /* Readable font size */
    padding: 10px 20px; /* Comfortable padding around the text */
    color: #ffffff; /* White text */
    background-color: #007bff; /* Bootstrap primary blue */
    border: none; /* No border for a cleaner look */
    border-radius: 3px; /* Slightly rounded corners to match the form style */
    cursor: pointer; /* Changes cursor to pointer to indicate clickability */
    transition: background-color 0.3s ease; /* Smooth transition for hover effect */
}

.purchase-button:disabled {
    background-color: #cccccc; /* Grayed out button when disabled */
    cursor: not-allowed; /* Indicates the button is not clickable */
}

.purchase-button:hover:enabled {
    background-color: #0056b3; /* Darker blue on hover for visual feedback */
}

.price-info {
    font-size: 18px; /* Slightly larger font for emphasis */
    color: #333; /* Dark color for readability */
    font-weight: bold; /* Makes the price stand out */
}

.tab-content{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.payment-element{
    margin-top: 20px;
}
