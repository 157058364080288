.picture-detail {
  padding: 20px;
  text-align: center;
}

.picture-detail .back-button {
  background: none;
  border: none;
  color: #3700b3;
  font-weight: bold;
  font-size: 1em;
  cursor: pointer;
  margin-bottom: 20px;
}

.picture-detail .image-container {
  margin-bottom: 30px;
}

.picture-detail .selected-image {
  width: 100%;
  max-width: 500px;
  height: auto;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.picture-detail .action-buttons {
  display: flex;
  justify-content: center;
  gap: 20px;
}

.picture-detail .action-button {
  background-color: #6200ea;
  color: white;
  padding: 14px 20px;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s;
  width: 160px;
  margin-top: 24px;
  box-sizing: border-box;
}
