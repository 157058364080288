/* Login.css */
.login-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  /* Full height of the viewport */
  background-color: #f4f4f4;
  /* Background color */
}

.login-card {
  background-color: #fff;
  flex-direction: column;
  display: flex;
  align-items: center;
  width: 80vw;
  max-width: 400px;
  padding: 30px;
  text-align: center;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.login-title {
  margin-top: 20px;
  font-size: 18px;
  margin-bottom: 30px;
  font-weight: bold;
  text-align: center;
}

.login-notice-card {
  width: 80%;
  text-align: start;
  margin-bottom: 10px;
}

.login-notice {
  font-size: 12px;
  color: #555555;
}

.login-button {
  display: flex;
  align-items: center;
  font-size: 14px;
  color: white;
  padding: 2px 15px 2px 5px;
  border: none;
  font-weight: bold;
  border-radius: 4px;
  background-color: #06c755;
  cursor: pointer;
  margin-bottom: 30px;
  position: relative;
  /* 擬似要素を使用するために必要 */
  overflow: hidden;
  /* 擬似要素がボタンの外に出ないようにする */
  transition: background-color 0.3s;
}

.login-button::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* 黒に8%の透明度 */
  background-color: rgba(0, 0, 0, 0.1);
  /* 初期状態では透明 */
  opacity: 0;
  /* スムーズなトランジション */
  transition: opacity 0.3s ease;
}

.login-button:hover::after {
  opacity: 1;
}

.line-icon {
  width: 36px;
  /* アイコンのサイズ */
  height: 36px;
  /* アイコンのサイズ */
  margin-right: 16px;
  /* アイコンとテキストの間隔 */
}
