.thanks-page-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background: linear-gradient(135deg, #f6d365 0%, #fda085 100%);
    color: #333;
    padding: 20px;
    text-align: center;
  }
  
  .thanks-message {
    font-size: 2rem;
    color: #ffffff;
    margin-bottom: 20px;
    font-weight: bold;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
  }
  
  .qrcode {
    width: 200px;
    height: 200px;
    margin: 20px 0;
    border-radius: 10px;
    border: 5px solid white;
  }
  
  .instruction-message {
    font-size: 1.2rem;
    color: #ffffff;
    background-color: rgba(255, 255, 255, 0.2);
    padding: 20px;
    border-radius: 10px;
    margin-top: 20px;
  }
  
.take-picture-again-button {
  font-size: 1.2rem;
  color: #ffffff;
  background-color: #4CAF50;
  /* Updated color to a bright blue */
  padding: 10px 20px;
  border-radius: 10px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.take-picture-again-button:hover {
  background-color: #388E3C;
  /* Updated hover color to a darker blue */
}
  