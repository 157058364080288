.psyche-purchase {
  padding: 20px;
  text-align: center;
}

.mypage_header {
  width: 100%;
  display: flex;
  align-items: start;
  justify-content: left;
}

.mypage_header .back-button {
  background: none;
  border: none;
  color: #3700b3;
  font-weight: bold;
  font-size: 1em;
  cursor: pointer;
  margin-bottom: 20px;
}

.psyche-purchase h2 {
  font-weight: bold;
  margin-bottom: 20px;
}

.current-psyche {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
  gap: 20px;
}

.psyche-count-container {
  margin-left: 40px;
  font-size: 2em;
  margin-left: 10px;
}

.psyche-count {
  font-size: 22px;
  font-weight: bold;
}

.psyche-count-unit {
  font-size: 14px;
  font-weight: normal;
  display: flex;
  align-items: center;
}

.psyche-count-unit .psyche-icon {
  height: 1.2em;
}

.purchase-options {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.purchase-option {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 80%;
  max-width: 300px;
  padding: 15px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.option-amount {
  font-size: 1.2em;
  font-weight: bold;
  display: flex;
  align-items: center;
}

.option-amount .psyche-icon {
  height: 1.2em;
}

.option-price {
  font-size: 1.2em;
  font-weight: bold;
}
